import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Article2 from "../components/Article2"

const IndexPage = () => (
  <Layout headerColor={"black"}>
    <SEO title="L'art de manger un ramen" />
    <Article2 />
  </Layout>
)

export default IndexPage

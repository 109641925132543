import React from "react"
import styled from "@emotion/styled"
import articles from "../articles-db"

const Blog = () => {
  const article = articles.find(art => art.id === "2")

  return (
    <ArticleSection>
      <ArticleContainer>
        <ArticleTextContainer>
          <ArticleTitle>{article.title}</ArticleTitle>
          <ArticleDate>Publié le {article.date}</ArticleDate>

          <ArticleImageContainer>
            <img
              src={article.image}
              alt={article.title}
              className="articleImage"
            ></img>
          </ArticleImageContainer>
          <ArticleIntro>{article.intro}</ArticleIntro>
          <ArticleSubTible>
            Le ramen, un plat qui se mange rapidement
          </ArticleSubTible>
          <ArticleText>
            En principe, le ramen est un plat qui doit se manger très vite. Au
            japon, les restaurants de ramen sont souvent assimilés à des « fast
            food » car les japonais déjeunent rapidement cette soupe bon marché.
            Cependant, il existe une vraie raison de manger vite son ramen : le
            bouillon étant servi très chaud, il est conseillé de se dépêcher de
            déguster les nouilles pour qu’elles ne continuent pas à cuire. Mais
            chez Hara-Kiri vous pouvez bien entendu prendre tout le temps que
            vous souhaitez, en dégustant une bière ou un saké, dont la maison
            est spécialisée.
          </ArticleText>
          <ArticleSubTible>Comment manger ses nouilles ?</ArticleSubTible>
          <ArticleText>
            Au Japon, lorsqu’on aspire bruyamment ses nouilles, on dit qu’on
            « zuru zuru ». En effet c’est une manière traditionnelle de manger
            son ramen. Dans la culture française, il n’est pas très apprécié de
            faire du bruit en mangeant. Pourtant les japonais ont de bonnes
            raisons de le faire : aspirer rapidement et bruyamment les nouilles,
            permet aux arômes du bouillon de s’accroitre tout en refroidissant
            les nouilles. Cela pourrait être comparé à notre manière de déguster
            le vin en France, c’est-à-dire en l’aspirant tout en laissant de
            l’air passer.
            <br></br>
            <br></br>
            Si toutefois vous n’êtes pas très à l’aise avec cette technique de
            dégustation, il en existe une autre, tout aussi pratiquée par les
            japonais. Il s’agit tout simplement de déposer des nouilles dans
            votre cuillère pour ensuite les aspirer. Vous avez bien entendu le
            droit de rester silencieux, chez Hara-Kiri, on ne vous en voudra pas
            !
          </ArticleText>
          <ArticleSubTible>
            Déguster son bouillon comme au Japon
          </ArticleSubTible>
          <ArticleText>
            Dans la culture japonaise, il est autorisé de porter son bol à deux
            mains jusqu’à vos lèvres pour terminer le bouillon. Rappelons
            toutefois quelques règles indispensables de courtoisie quant à
            l’utilisation de vos baguettes : au Japon il ne faut jamais pointer
            quelqu’un avec ni les planter dans son bol de riz. Il ne faut pas
            non plus les mordiller ou les lécher, cela serait très mal perçu.
            <br></br>
            <br></br>
            Maintenant que vous détenez tous les codes pour déguster votre ramen
            tel un vrai japonais, il nous reste qu’à vous dire itadakimasu, qui
            signifie bon appétit !
          </ArticleText>
        </ArticleTextContainer>
      </ArticleContainer>
    </ArticleSection>
  )
}

const ArticleSection = styled.section`
  margin-top: 100px;
  padding: 0 10%;
`

const ArticleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`

const ArticleTextContainer = styled.div`
  width: 80%;
  /* padding: 20px 60px; */
  margin: auto;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 20px;
  }
`

const ArticleTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
`
const ArticleDate = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
`
const ArticleIntro = styled.p`
  font-size: 18px;
  margin-bottom: 40px;
`

const ArticleSubTible = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`

const ArticleText = styled.p`
  font-size: 14px;
  margin-bottom: 40px;
`

const ArticleImageContainer = styled.div`
  width: 100%;
  height: 500px;
  background-color: #fff;
  margin-bottom: 50px;

  .articleImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 640px) {
    width: 80vw;
    height: 80vw;
  }
`

export default Blog
